import { NSPayment } from 'gga-types'
import { ReactNode, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { formatDateTime } from '@/pages/live-session/components/helpers'
import { Currency } from '@/utils/currency'
import { DataLayerObject, pushDataLayer } from '@/utils/gtm'
import { CoachPlatformNotice } from './coach-platform-notice'
import { TrialNotice } from './TrialNotice'
import { TokenProductNotice } from './token-product-notice'

interface PaymentDetailsProps {
  order: NSPayment.Order
}

export const Row = ({ label, text }: { label; text }) => {
  return (
    <div className="flex items-center justify-between">
      <div className="text-[#999]">{label}:</div>
      <div>{text}</div>
    </div>
  )
}

export const Block = ({ children }: { children: ReactNode }) => {
  return <div className="flex flex-col space-y-2.5 py-2.5">{children}</div>
}

export const PaymentDetails = ({ order }: PaymentDetailsProps) => {
  const { t } = useTranslation('payment-status')
  const lo = useMemo(() => {
    return order.product_order_snapshot.localization.code
  }, [order.product_order_snapshot.localization.code])

  const isTrial = order.product_order_snapshot.class?.type === 'Trial'
  const hasCalendarEventTitle = !!order.calendar_event_title

  const productAmount = useMemo(() => {
    return (
      order.product_order_snapshot.discount_amount ||
      order.product_order_snapshot.amount
    )
  }, [
    order.product_order_snapshot.amount,
    order.product_order_snapshot.discount_amount,
  ])

  useEffect(() => {
    if (order && order.status === 'Success') {
      const paymentAmount = new Currency(
        order.amount,
        order.product_order_snapshot.localization.code
      )
      const data: DataLayerObject = {
        event: 'purchase',
        ecommerce: {
          currency: paymentAmount.getCurrencyName() as any,
          value: order.amount,
          transaction_id: order.biz_id,
          items: [
            {
              item_id:
                order.product_order_snapshot.original_product_id.toString(),
              item_name: order.product_order_snapshot.name,
              price: order.amount,
              quantity: 1,
            },
          ],
        },
      }

      pushDataLayer(data)
    }
  }, [order])

  return (
    <div className="divide-y divider-[#666] divide-dashed border-t border-dashed">
      <Block>
        {order.addition_type !== 'EventEnrollment' && !isTrial && (
          <Row
            label={!hasCalendarEventTitle ? '课包名称' : t('program-name')}
            text={order?.product_order_snapshot.name}
          />
        )}

        {order.addition_type === 'EventEnrollment' && (
          <>
            <Row
              label={t('program-name')}
              text={order.calendar_event_title!.name}
            />
            <Row
              label={t('class_date')}
              text={formatDateTime(
                new Date(order.calendar_event_title!.event_start_date_time),
                new Date(order.calendar_event_title!.event_end_date_time),
                false,
                t
              )}
            />
          </>
        )}

        {isTrial && <Row label={t('购买手机号')} text={order?.phone_number} />}
      </Block>
      <Block>
        <Row
          label={t('list_price', {
            ns: 'buynow',
          })}
          text={new Currency(
            order.product_order_snapshot.amount,
            lo
          ).renderWithSymbol()}
        />

        <Row
          label={t('discount', {
            ns: 'cashier',
          })}
          text={new Currency(
            productAmount - order.product_order_snapshot.amount,
            lo
          ).renderWithSymbol()}
        />

        <Row
          label={t('extra-discount', {
            ns: 'cashier',
          })}
          text={new Currency(
            order.amount - productAmount,
            lo
          ).renderWithSymbol()}
        />

        <Row
          label={t('total-amount', {
            ns: 'cashier',
          })}
          text={new Currency(order.amount, lo).renderWithSymbol()}
        />
      </Block>

      {hasCalendarEventTitle ? (
        order.addition_type === 'EventEnrollment' && (
          <Block>
            <CoachPlatformNotice />
          </Block>
        )
      ) : (
        <Block>
          <TokenProductNotice />
        </Block>
      )}

      {isTrial && (
        <Block>
          <TrialNotice />
        </Block>
      )}
    </div>
  )
}
