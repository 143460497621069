import React from 'react'
import classNames from 'classnames'
import { Button } from '@mantine/core'
import { useTranslation } from 'next-i18next'
import UserDropdown from '@/components/HeaderBar/profile'
import NavLink from '@/components/NavLink'
import style from '@/components/HeaderBar/styles.module.scss'
import MobileNavLink from '@/components/HeaderBar/mobile/MobileNavLink'
import { APP_LOCALE, HOMEPAGE_DYNAMIC } from '@/constants/define'
import ImageComponent from '@/components/ImageComponent'
import { useAuth } from '@/containers/auth'

export default function MobileHeader({ isAuthenticated }) {
  const { i18n } = useTranslation()
  const { setOpenAuthModal } = useAuth()
  return (
    <header
      className={classNames(
        style.header,
        'px-2 flex flex-nowrap items-center justify-between bg-black opacity-90 backdrop-filter backdrop-filter-sm z-100 h-navMob fixed top-0 right-0 left-0'
      )}
    >
      <MobileNavLink />
      <NavLink href={HOMEPAGE_DYNAMIC(i18n)}>
        <ImageComponent
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-7 lg:hidden"
          src="/images/logo-transparent.png"
        />
      </NavLink>

      {!isAuthenticated && APP_LOCALE === 'zh_CN' && (
        <Button size="sm" onClick={() => setOpenAuthModal(true)} color="brand">
          登录/注册
        </Button>
      )}

      {isAuthenticated && <UserDropdown />}
    </header>
  )
}
