import React, { HTMLAttributes } from 'react'

type IconProps = HTMLAttributes<SVGElement>

export const UploadIcon = () => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
    >
      <path
        d="M768 512l-96 0C654.08 512 640 526.08 640 544 640 561.92 654.08 576 672 576L768 576c35.2 0 64 28.8 64 64l0 192c0 35.2-28.8 64-64 64L192 896c-35.2 0-64-28.8-64-64l0-192c0-35.2 28.8-64 64-64l96 0C305.92 576 320 561.92 320 544 320 526.08 305.92 512 288 512L192 512c-70.4 0-128 57.6-128 128l0 192c0 70.4 57.6 128 128 128l576 0c70.4 0 128-57.6 128-128l0-192C896 569.6 838.4 512 768 512zM186.88 375.04 448 113.28l0 560C448 689.92 462.08 704 478.72 704c17.28 0 30.72-14.08 30.72-30.72L509.44 113.28l261.12 261.76c13.44 14.08 35.84 14.08 49.92 0 13.44-14.08 13.44-35.84 0-49.92l-312.96-313.6C503.04 6.4 497.28 3.84 490.88 2.56 487.68 0.64 483.2 0 478.72 0S470.4 0.64 466.56 2.56C460.8 3.84 454.4 6.4 449.92 11.52l-312.96 313.6c-13.44 14.08-13.44 35.84 0 49.92C151.04 388.48 172.8 388.48 186.88 375.04z"
        fill="#9E9E9E"
      ></path>
    </svg>
  )
}

interface TickIconProps extends IconProps {
  width?: number
  height?: number
}

export const TickIcon = (props: TickIconProps) => {
  const { width = 128, height = 128, ...restProps } = props
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      {...restProps}
    >
      <path
        d="M512 0C229.668571 0 0 229.668571 0 512s229.668571 512 512 512 512-229.668571 512-512S794.331429 0 512 0z m307.2 343.771429s-267.702857 295.497143-327.68 365.714285c-59.977143 70.217143-106.788571 0-106.788571 0L210.651429 529.554286s-27.794286-42.422857 21.942857-81.92c46.811429-38.034286 84.845714 0 84.845714 0l122.88 128.731428L746.057143 291.108571s29.257143-20.48 59.977143 5.851429c23.405714 21.942857 13.165714 46.811429 13.165714 46.811429z"
        fill="#00c044"
      ></path>
    </svg>
  )
}

export const CommentIcon = (props: IconProps) => {
  return (
    <svg
      className="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      {...props}
    >
      <path
        d="M920.408 0H115.408C57.84 0 0 66.96 0 124.488v613C0 795.016 57.84 832 115.408 832h225.84l152.752 175.448c6.064 6.752 14.752 13.104 23.84 13.104s17.752-7.552 23.84-14.336L694.472 832h225.936C977.936 832 1024 795.016 1024 737.488V124.488C1024 66.96 977.936 0 920.408 0zM960 737.488c0 22.216-17.376 30.512-39.592 30.512H680.184c-9.096 0-17.752 8.776-23.84 15.552L517.84 940.576l-138.44-158.24c-6.056-6.784-14.744-14.336-23.84-14.336h-240.16C93.16 768 64 759.704 64 737.488V124.488C64 102.256 93.16 64 115.408 64h805c22.216 0 39.592 38.256 39.592 60.488v613z"
        fill="#989898"
      ></path>
      <path
        d="M819.784 256h-596c-17.688 0-32 14.328-32 32s14.312 32 32 32h596c17.688 0 32-14.328 32-32s-14.312-32-32-32zM527.84 480H223.784c-17.688 0-32 14.328-32 32s14.312 32 32 32H527.84c17.688 0 32-14.328 32-32s-14.312-32-32-32z"
        fill="#989898"
      ></path>
    </svg>
  )
}

export const CommentQIcon = () => {
  return (
    <svg
      className="icon align-middle text-center"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
    >
      <path
        d="M555.437 744.856c9.653-3.219-9.653-24.132-30.567-38.61-27.348-19.305-69.178-22.523-69.178-22.523v-61.134s57.917-16.090 111.006 0c53.090 16.090 74.004 65.962 77.222 61.134 1.609-1.609 6.434-8.043 8.043-9.653 35.394-51.483 53.090-120.659 53.090-209.145 0-85.266-17.696-154.447-53.090-205.926-35.394-51.483-82.049-77.222-141.573-77.222-59.525 0-106.181 25.741-143.184 77.222-35.394 51.483-53.090 120.659-53.090 207.534 0 85.266 17.696 154.447 53.090 205.926 35.394 51.483 82.049 78.831 139.964 78.831 17.696-1.609 33.786-3.219 48.265-6.434zM819.279 939.52s-88.485 22.523-141.573-8.043c-41.828-24.132-75.615-75.615-78.831-77.222-24.132 9.653-80.441 9.653-91.7 9.653-112.617 0-202.708-35.394-270.278-106.181-67.569-70.787-101.356-165.705-101.356-284.757 0-120.659 33.786-215.58 101.356-286.366 67.569-70.787 159.27-106.181 275.104-106.181 115.833 0 207.534 35.394 275.104 106.181s101.356 167.315 101.356 287.973c0 157.662-56.308 270.278-170.532 339.456 0 0 9.653 20.914 30.567 32.174 19.305 11.263 57.917 9.653 57.917 9.653l12.87 83.658z"
        fill="#ffffff"
        p-id="13815"
      ></path>
    </svg>
  )
}

export const CaptialIcon = () => {
  return (
    <svg
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
    >
      <path
        d="M512 16C238.066 16 16 238.066 16 512s222.066 496 496 496 496-222.066 496-496S785.934 16 512 16z m234.268 693.506c-3.184 3.734-79.552 91.462-219.702 91.462-169.384 0-288.968-126.52-288.968-291.134 0-162.606 124.008-286.802 287.524-286.802 133.914 0 203.93 74.63 206.844 77.808a24 24 0 0 1 2.476 29.246l-44.76 69.31c-8.098 12.534-25.548 14.702-36.468 4.59-0.466-0.428-53.058-47.76-123.76-47.76-92.232 0-147.832 67.15-147.832 152.164 0 79.204 51.028 159.384 148.554 159.384 77.394 0 130.56-56.676 131.088-57.25 10.264-11.13 28.118-10.066 37.016 2.106l49.094 67.144a24.002 24.002 0 0 1-1.106 29.732z"
        fill="#AB8C2F"
      ></path>
    </svg>
  )
}
