import 'intersection-observer'
import 'styles/index.global.scss'
import 'react-toastify/dist/ReactToastify.css'
import { useEffect } from 'react'
import { appWithTranslation } from 'next-i18next'
import { AppComponent, AppShell } from '@/components/App'
import nextI18NextConfig from '@/next-i18next.config'
import '@/utils/prototype'
import ContactWrapper from '@/components/kr/ContacWrapper'
import { ChannelIOService } from '@/services/channel-io-service'

function App({ Component, pageProps }) {
  useEffect(() => {
    ChannelIOService.bootstrap()
  }, [])

  return (
    <AppShell {...pageProps}>
      <ContactWrapper hide={pageProps.hideContact}>
        <AppComponent Component={Component} pageProps={pageProps} />
      </ContactWrapper>
    </AppShell>
  )
}

export default appWithTranslation(App, nextI18NextConfig as any)
