import React from 'react'
import { useAuth } from '@/containers/auth'
import { IPageProps, IPagePropsBase } from '@/@types/global'
import Footer from '@/components/kr/footer'
import HeaderBar from '@/components/HeaderBar'
import PageMeta from '@/components/page-meta'
import { LogoBg } from '../LogoBg'

const Layout = (props: IPagePropsBase) => {
  const authContext = useAuth()
  const pageProps: IPageProps = { ...props, ...authContext }
  const childrenWithProps = React.Children.map(props.children, child => {
    return React.cloneElement(child, {
      ...authContext,
    })
  })

  return (
    <>
      <PageMeta {...pageProps} />
      <HeaderBar {...pageProps} />
      <LogoBg className="flex flex-col">{childrenWithProps}</LogoBg>
      <Footer {...pageProps} />
    </>
  )
}

export default Layout
