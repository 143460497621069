import React, { useEffect } from 'react'
import { isBrowser } from 'react-device-detect'
import { ChannelIOService } from '@/services/channel-io-service'
import Discord from './Discord'
import Kakao from './Kakao'

const ContactWrapper: React.FC<
  React.PropsWithChildren<{
    hide?: boolean
  }>
> = ({ hide = false, children }) => {
  useEffect(() => {
    if (isBrowser) {
      if (hide) {
        ChannelIOService.instance.hideChannelButton()
      } else {
        ChannelIOService.instance.showChannelButton()
      }
    }
  })

  return (
    <>
      {children}
      {hide ? null : (
        <>
          <Discord />
          <Kakao />
        </>
      )}
    </>
  )
}

export default ContactWrapper
