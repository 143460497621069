import Router from 'next/router'
import { GetServerSidePropsContext } from 'next'
import { Jwt } from '@/services/jwt'
import { QueryUtils } from '@/utils'
import { UserApi } from '@/api/user/index.api'
import { BaseService } from '@/services/base'

export class AuthService extends BaseService {
  /**
   * True表示有token字段并已经存入cookie中, false表示没有任何操作
   * @return boolean
   */
  readTokenFromUrl() {
    const query = Router.query
    if (query && Object.prototype.hasOwnProperty.call(query, 'token')) {
      const token = QueryUtils.getQueryItemFromRouter(query, 'token')
      Jwt.setToken(token)
      return true
    }
    return false
  }

  /**
   * 如果token不正确会清空cookie中存的token
   * @return IUserProfile?
   */
  async refreshUserByToken() {
    const token = Jwt.getToken()
    if (token) {
      const { data } = await new UserApi().getUserInfo()
      if (data && data.data) {
        return data.data
      }
      Jwt.clear()
    }
  }

  async loginByWxLoginCode(code: string) {
    const userApi = new UserApi(this.context)
    const res = await userApi.loginByWechatWeb({
      login_code: code,
    })
    if (res.data?.data.access_token) {
      Jwt.setToken(res.data.data.access_token, this.context)
      return true
    }
    return false
  }
}
