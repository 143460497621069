const ApiDomain = process.env.API_URL || 'https://localhost:5001'

const DotNetDomain = process.env.API_URL || 'https://localhost:5001'

const OSSRegion = process.env.OSS_REGION

const OSSBucket = process.env.OSS_BUCKET

const OSSDownloadRootUrl =
  process.env.OSS_DOWNLOAD_ROOT_URL ||
  'https://gga-data-dev.oss-cn-shanghai.aliyuncs.com/'

const OSSCdnUrl = process.env.OSS_CDN_URL
const AwsS3Url = `https://${process.env.AWS_S3_BUCKET}.s3.ap-northeast-2.amazonaws.com/`

const SentryDSN = process.env.SENTRY_DSN || ''
const ENV = process.env.ENV
const APP_ENV = process.env.APP_ENV

const TossPayments_Client_Key = process.env.TOSSPAYMENTS_CLIENT_KEY
const TossPayments_Recurring_Client_Key =
  process.env.TOSSPAYMENTS_RECURRING_CLIENT_KEY

const TossPayments_PayPal_Client_Key =
  process.env.TOSSPAYMENTS_PAYPAL_CLIENT_KEY

const UserWebUrl = {
  kr: process.env.USER_WEB_URL_KR,
  zh_CN: process.env.USER_WEB_URL_CN,
  en_US: process.env.USER_WEB_URL_EN,
}

const STRAPI_REQUEST_TOKEN = process.env.STRAPI_REQUEST_TOKEN
const STRAPI_ENDPOINT = process.env.STRAPI_ENDPOINT

const WX_WEB_APP_ID = process.env.WX_WEB_APP_ID

// GENGD
const config = {
  ApiDomain,
  DotNetDomain,
  OSS_CONSTANT: {
    Region: OSSRegion,
    Hots: 'aliyuncs.com',
    Bucket: OSSBucket,
    DownloadRootUrl: OSSDownloadRootUrl,
  },
  SentryDSN,
  ENV,
  APP_ENV,
  AwsS3Url,
  OSSCdnUrl,
  TossPayments_Client_Key,
  TossPayments_Recurring_Client_Key,
  TossPayments_PayPal_Client_Key,
  IMAGE_PROXY_HOST: process.env.IMAGE_PROXY_HOST,
  UserWebUrl,
  STRAPI_ENDPOINT,
  STRAPI_REQUEST_TOKEN,
  WX_WEB_APP_ID,
}

module.exports = config
